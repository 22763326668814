import React from 'react'

// Components
import Layout from '../../components/layout.js'
import SEO from '../../components/seo.js'

// SVGs

export default () => (
    <Layout className="about">
        <SEO
            title="About"
            description="Learn more about who I am and how I like to make my morning cup of coffee, which tells a lot about a person."
        />
        <section className="padding-top-12 padding-bottom-6 hero position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 offset-lg-1 order-lg-1 col-md-6 offset-md-3 col-8 offset-2 margin-bottom-3">
                        <img
                            src="http://d2jotzgct8q460.cloudfront.net/svgs/About-Hero.svg"
                            alt="Hero SVG"
                            className="hero-svg"
                        />
                    </div>
                    <div className="col-lg-7 col-md-12 order-lg-0 align-self-center">
                        <h3 className="weight-500 margin-bottom-3 hero__title">
                            I’m passionate about the outdoors and love getting
                            my hands dirty with new tech. I have a degree in
                            Computer Science from Worcester Polytechnic
                            Insitute. Learning UI and data-viz development has
                            fueled my love for{' '}
                            <span className="red-underline hover">Figma</span>,{' '}
                            <span className="red-underline hover">React</span>,{' '}
                            <span className="red-underline hover">GraphQL</span>
                            , and{' '}
                            <span className="red-underline hover">D3 JS</span>.
                            Outside of coding you can find me working on my
                            motorcycle and searching new coffee shops to go to.
                        </h3>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="cta-row">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://d2jotzgct8q460.cloudfront.net/files/Resume-Ian-Banatoski.pdf"
                                        className="secondary-button hover__red margin-right-3 margin-bottom-1"
                                    >
                                        Resume
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="mailto:ibanatoski@gmail.com"
                                        className="primary-button primary-button__red hover margin-bottom-1"
                                    >
                                        Hire Me
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-6 position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-12 margin-bottom-3">
                                <div className="card__about lotr-quote">
                                    <h3 className="margin-bottom-3">
                                        <span className="highlight highlight__red">
                                            “It's the job that's never started
                                            as takes longest to finish.”
                                        </span>
                                    </h3>
                                    <p>
                                        <i>
                                            - J.R.R. Tolkien, The Lord of the
                                            Rings
                                        </i>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 margin-bottom-3">
                                <div className="card__about designers">
                                    <h3 className="margin-bottom-3">
                                        <span className="highlight highlight__red">
                                            Designers and Developers that
                                            inspire me!
                                        </span>
                                    </h3>

                                    <div className="margin-bottom-2">
                                        <h4>
                                            <a
                                                className="color-red-1"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://wattenberger.com/"
                                            >
                                                Amelia Wattenberger
                                            </a>
                                        </h4>
                                        <a
                                            className="twitter-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="http://twitter.com/wattenberger"
                                        >
                                            @Wattenberger
                                        </a>
                                    </div>
                                    <div className="margin-bottom-2">
                                        <a
                                            className="color-red-1"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://peterbeshai.com/"
                                        >
                                            <h4>Peter Beshai</h4>
                                        </a>
                                        <a
                                            className="twitter-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://twitter.com/pbesh"
                                        >
                                            @pbesh
                                        </a>
                                    </div>
                                    <div className="margin-bottom-2">
                                        <a
                                            className="color-red-1"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://adamwathan.me/"
                                        >
                                            <h4>Adam Wathan</h4>
                                        </a>
                                        <a
                                            className="twitter-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://twitter.com/adamwathan"
                                        >
                                            @adamwathan
                                        </a>
                                    </div>
                                    <div className="margin-bottom-2">
                                        <a
                                            className="color-red-1"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.steveschoger.com/"
                                        >
                                            <h4>Steve Schoger</h4>
                                        </a>
                                        <a
                                            className="twitter-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://twitter.com/steveschoger"
                                        >
                                            @steveschoger
                                        </a>
                                    </div>
                                    <div className="">
                                        <a
                                            className="color-red-1"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.pablostanley.com/"
                                        >
                                            <h4>Pablo Stanley</h4>
                                        </a>
                                        <a
                                            className="twitter-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://twitter.com/pablostanley"
                                        >
                                            @pablostanley
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 margin-bottom-3">
                                <div className="card__about bucket-list">
                                    <h3 className="margin-bottom-3">
                                        <span className="highlight highlight__red">
                                            Bucket List
                                        </span>
                                    </h3>

                                    <h4 className="margin-bottom-3">
                                        Go on a motorcycle camping trip to
                                        Labrador Canada
                                    </h4>
                                    <h4 className="margin-bottom-3">
                                        Learn to paraglide
                                    </h4>
                                    <h4 className="margin-bottom-3">
                                        Go a full year without using a single
                                        disposable cup
                                    </h4>
                                    <h4 className="margin-bottom-0">
                                        Go hiking in Chile/Argentina
                                    </h4>
                                </div>
                            </div>

                            <div className="col-md-12 margin-bottom-3">
                                <div className="card__about books">
                                    <h3 className="margin-bottom-3">
                                        <span className="highlight highlight__red">
                                            Books Im Reading Right Now
                                        </span>
                                    </h3>
                                    <div className="padding-y-2">
                                        <div className="margin-bottom-2">
                                            <a
                                                className="color-purple-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://refactoringui.com/"
                                            >
                                                <h4>Reactoring UI</h4>
                                            </a>
                                            <p>
                                                <i>
                                                    by Adam Wathan &amp; Steve
                                                    Schoger
                                                </i>
                                            </p>
                                        </div>
                                        <div className="margin-bottom-2">
                                            <a
                                                className="color-purple-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.newline.co/fullstack-d3"
                                            >
                                                <h4>
                                                    Fullstack D3 and Data
                                                    Visualization
                                                </h4>
                                            </a>
                                            <p>
                                                <i>by Amelia Wattenberger</i>
                                            </p>
                                        </div>
                                        <div>
                                            <h4 className="color-purple-2">
                                                Aurora
                                            </h4>
                                            <p>
                                                <i>by Kim Stanley Robinson</i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-12 margin-bottom-3">
                                <div className="card__about podcast">
                                    <h3 className="">
                                        <span className="highlight highlight__blue">
                                            Favorite Podcast
                                        </span>
                                    </h3>
                                    <div className="img-container padding-y-3">
                                        <img
                                            src="http://d2jotzgct8q460.cloudfront.net/imgs/ReplyAll.jpg"
                                            alt="Reply All"
                                        />
                                    </div>
                                    <h4 className="">
                                        <span className="highlight highlight__blue">
                                            “A podcast about the internet’ that
                                            is actually an unfailingly original
                                            exploration of modern life and how
                                            to survive it.” – The Guardian
                                        </span>
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-12 margin-bottom-3">
                                <img
                                    src="http://d2jotzgct8q460.cloudfront.net/svgs/Comic.svg"
                                    alt="Comic SVG"
                                />
                            </div>
                            <div className="col-md-12 margin-bottom-3">
                                <div className="card__about coffee">
                                    <h3 className="margin-bottom-3">
                                        <span className="highlight">
                                            How to make the perfect cup of
                                            coffee
                                        </span>
                                    </h3>

                                    <div className="margin-bottom-2">
                                        <ol>
                                            <li>
                                                <h4>
                                                    Get your favorite coffee
                                                    (preferably whole bean).
                                                </h4>
                                            </li>
                                            <li>
                                                <h4>
                                                    Boil your water to 205&#176;
                                                    F.
                                                </h4>
                                            </li>
                                            <li>
                                                <h4>
                                                    Grind 22 grams of beans.
                                                </h4>
                                            </li>
                                            <li>
                                                <h4>
                                                    Prep your favorite coffee
                                                    mug by filling it with hot
                                                    water to warm it up.
                                                </h4>
                                            </li>
                                            <li>
                                                <h4>
                                                    Prep your pour over coffee
                                                    cone by placing a paper
                                                    filter in it and wetting the
                                                    filter with hot water to
                                                    wash away the paper taste.
                                                </h4>
                                            </li>
                                            <li>
                                                <h4>
                                                    Pour freshly ground beans
                                                    into paper filter inside the
                                                    cone and wet grounds with a
                                                    splash of hot water. Wait
                                                    for 60 seconds while the
                                                    coffee grounds “bloom” and
                                                    suficently degas.
                                                </h4>
                                            </li>
                                            <li>
                                                <h4>
                                                    Empty your cup to remove hot
                                                    water and initial coffee
                                                    residue.
                                                </h4>
                                            </li>
                                            <li>
                                                <h4>
                                                    Pour hot water over coffee
                                                    in a circular motion until
                                                    your cup is full.
                                                </h4>
                                            </li>
                                            <li>
                                                <h4>
                                                    Finally, enjoy that amazing
                                                    cup of coffee you just made!{' '}
                                                    <span
                                                        role="img"
                                                        aria-label="coffee-emoji"
                                                    >
                                                        ☕️
                                                    </span>
                                                </h4>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)
